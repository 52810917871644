import styled from 'styled-components';
import { QuoteDataHeadPrice } from 'app/Pages/Quote/Profile/body/Quote/styles';
import { ControlsButton } from 'app/Pages/Quote/Profile/body/partials/ControlsNextStep/styles';

export const ComparisonTableRow = styled.div`
  display: flex;
  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.textOff};
  }
`;

export const ComparisonTableForm = styled.div`
  position: relative;
  margin-top: 16px;
  border: 1px solid ${({ theme }) => theme.colors.textOff};
  border-radius: ${({ theme }) => theme.borderRadius};
`;

export const ComparisonTableCol = styled.div<{ infoCol?: boolean }>`
  display: flex;
  flex-direction: column;
  flex: ${({ infoCol }) => infoCol ? 1 : 3};
  min-width: 120px;
  padding: 16px;
  &:not(:last-child) {
    border-right: 1px solid ${({ theme }) => theme.colors.textOff};
  }
`;

export const ProductDescription = styled.p`
  padding-top: 20px;
  ${({ theme }) => theme.text.normal};
`;

export const ProductTitle = styled.p`
  ${({ theme }) => theme.text.h3Strong};
`;

export const ButtonIssuePolicy = styled(ControlsButton)`
  min-width: auto;
`;

export const OfferPremia = styled(QuoteDataHeadPrice)`
  padding-top: 6px;
  margin-top: auto;
`;
