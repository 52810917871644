import React from 'react';
import { IntercomProvider } from 'react-use-intercom';

import { ProvideAuth } from 'context/Auth';
import { ProvideScheme } from 'context/Schema';
import { ProvideApp } from 'context/App';

import Routes from './routes';
import Misc from './Components/Misc';
import useHotjar from 'react-use-hotjar';
import { isIframe } from 'utils';

const INTERCOM_APP_ID = window._env_.INTERCOM_APP_ID;
export const INTERCOM_SHOULD_INITIALIZE = window._env_.STAGE !== 'local' && !isIframe();

const App = () => {
  const { initHotjar } = useHotjar();

  React.useEffect(() => {
    if (window._env_.HOTJAR_CODE) {
      initHotjar(+(window._env_.HOTJAR_CODE), 6, false, console.info);
    }
  }, [initHotjar]);

  return (
    <ProvideAuth>
      <ProvideScheme>
        <ProvideApp>
          <IntercomProvider
            autoBoot
            shouldInitialize={INTERCOM_SHOULD_INITIALIZE}
            appId={INTERCOM_APP_ID}
          >
            <>
              <Routes />
              <Misc />
            </>
          </IntercomProvider>
        </ProvideApp>
      </ProvideScheme>
    </ProvideAuth>
  );
};

export default App;
