import {
  JSONSchema,
  JSONSchemaProp,
  TCustomer,
  TCustomerContact,
  TDoc,
  TError,
  TFetchStatus
} from 'models';
import React, { useEffect, useRef, useState } from 'react';
import {
  ExistedIDD,
  IDDFormWrapper,
  IDDWrapper,
  ControlsRow,
  IddInput,
  IDDNumbersWrapper,
  IDDNumbersItemWrapper,
  SelectStatus,
  AddPolicyNumberWrapper,
  AddPolicyNumberInput,
  IDDNumbersWrapperBody,
  IDDButton,
  IDDControlsButton,
  IDDControlsIcon,
  IDDTitle,
  IDDTable,
  ControlsRowWrapper,
  IDDNumberColData,
  IDDSubTitle,
} from './styles';
import { DefaultIcon, Loader } from 'styles/common';
import { renderErrors } from 'app/Components/common/Error';
import {
  cloneObject,
  makeRequestQuery,
  preflightAPICall,
  processAPIError,
  processAPIQuoteError,
  scrollToFirstError,
  setRules,
  translate,
  translateTitleInScheme,
  validate
} from 'utils';
import { Button, Input, Tooltip } from '@insly/qmt-reactjs-ui-lib';
import axios, { AxiosError } from 'axios';
import { checkShowIf } from 'utils/JSONSchemaFormBuilder';
import { renderIDDAdditionalProperties } from 'utils/JSONSchemaFormBuilder/IDD';
import apiConfig, { DEFAULT_LOCALE } from 'config/api';
import LoadingOverlay from 'helpers/LoadingOverlay';
import { TIDD } from 'app/Pages/IDD';
import { getFile } from 'utils/getFile';
import { ButtonTransparent, ParticipantsBlockHead } from 'app/Pages/Quote/Profile/body/Participants/styles';
import { IconPlus } from 'app/Pages/Quote/Profile/body/styles';
import DocumentsList from '../DocumentsList';
import { TEMP_IDD_DOCUMENT_TYPE_ID } from 'app/Pages/IDD/consts';
import { ButtonInner } from 'app/Pages/Quote/Profile/body/partials/ControlsNextStep/styles';
import { getEmailTemplate, setDialogProps } from '../EmailPreview';
import { useApp } from 'context/App';
import {
  EditStatusIcon,
  StatusCol,
  StatusTitle,
  StatusList,
  StatusListItem
} from 'app/Pages/Clients/profile/body/GDPR/styles';
import { ColData } from 'app/Pages/common/List/styles';
import { useOutsideClick } from 'hooks';
import { useAuth } from 'context/Auth';

const MANUAL_STATUSES = [1, 4];
//QuestionnaireStatusNeedApproval = 1
//QuestionnaireStatusApproved = 2
//QuestionnaireStatusApprovalEmailSent = 3
//QuestionnaireStatusApprovedManually = 4

const TEMP_SCHEMA_PATH = 'idd/default';
const API_GET_SCHEME_URL = `${apiConfig.IDD}/questionnaire/schema`;
const API_IDD_SCHEME_URL = `${apiConfig.QMT_SCHEMAS}`;
export const API_IDD_QUESTIONNAIRE_URL = `${apiConfig.IDD}/questionnaire`;

const STATUS_ICONS: Record<number, string> = {
  1: 'pending',
  2: 'accepted',
  3: 'pending',
  4: 'accepted',
};

const COMMENT_FIELDS = ['agricultural', 'car', 'company', 'inthefuture', 'life', 'property', 'travel'];

export type TIDDState = 'exist' | 'not_exist' | 'idd_requested' | 'schema_fetched' | 'to_check_if_exist';

type TStatusRelatedControl = {
  icon: string,
  title: string,
  action?: () => void,
};

type TStatusRelatedControls = Record<number, TStatusRelatedControl[]>;

type TIDDTable = {
  number: string,
  status: number,
  updated_at: string,
};

interface IIDD {
  customerData?: TIDD['customer_data'],
  quoteId?: string,
  idd?: TIDD,
  handleIddSave?: ((data?: TIDD) => void),
  handleIDDUpdate?: ((data?: TIDD) => void),
  handleIddCancel?: (() => void),
  initialIDDState?: TIDDState,
  showOnly?: boolean,
  hidePolicyNumber?: boolean,
  updateOutsideErrors?: (errors: TError []) => void,
  updateHighlighted?: (highlighted: boolean) => void,
  highlighted?: boolean,
  readOnly?: boolean,
  isGDPRTouched?: boolean,
}

const IDD = ({
  customerData,
  quoteId,
  idd,
  handleIddSave,
  handleIddCancel,
  handleIDDUpdate,
  initialIDDState = 'not_exist',
  showOnly = false,
  hidePolicyNumber,
  updateHighlighted,
  highlighted,
  readOnly,
  isGDPRTouched
} : IIDD) => {

  const [iddState, updateIddState] = useState<TIDDState | null>(initialIDDState);
  const [schemaFetchState, updateSchemaFetchState] = useState<TFetchStatus>(null);
  const [schemaPath, updateSchemaPath] = useState('');
  const [schema, updateSchema] = useState<JSONSchemaProp | null>(null);
  const [errors, updateErrors] = useState<TError[]>([]);
  const [saveIDDState, updateSaveIDDState] = useState<TFetchStatus>(null);
  const [filledIdd, updateFilledIdd] = useState<TIDD | null>(null);
  const [isAddingNewIdd, updateIsAddingNewIdd] = useState(false);
  const [documentsList, updateList] = useState<TDoc[]>([]);
  const [isFormTouched, updateIsFormTouched] = useState(false);
  const [isStatusListOpened, updateIsStatusListOpened] = useState(false);
  const [isEditingIdd, updateIsEditingIdd] = useState(false);

  const { showDialog } = useApp();
  const { user } = useAuth();

  const fetchLatestIDDSchema = (isAddNewIdd?: boolean) => {
    if (isAddNewIdd) {
      updateIsAddingNewIdd(true);
      updateStatus(1, true);
    }

    if (schemaFetchState === 'success' && !isAddNewIdd) {
      updateIddState('schema_fetched');
      return;
    }

    preflightAPICall(() => {
      updateSchemaFetchState('loading');
      updateIddState('idd_requested');
      axios.get(API_GET_SCHEME_URL).then(schemeUrlResponse => {
        const path = schemeUrlResponse.data.schema_path;
        if (path) {
          updateSchemaPath(path);
        }
        fetchIddSchemaByPath(path);
      }).catch(error => {
        console.error(error);
        updateErrors(processAPIError(error, true, true) as TError[]);
        updateSchemaFetchState('failed');
      });

    });
  };

  const fetchCurrentIddSchema = () => {
    if (filledIdd?.schema_path !== schemaPath) {
      fetchIddSchemaByPath(filledIdd?.schema_path);
    } else {
      updateIddState('schema_fetched');
    }
  };

  const fetchIddSchemaByPath = (path?: string, statusToKeep?: TIDDState) => {
    if (schemaFetchState !== 'loading') {
      updateSchemaFetchState('loading');
    }
    if (iddState !== 'idd_requested') {
      updateIddState('idd_requested');
    }
    preflightAPICall(() => {
      axios.get(`${API_IDD_SCHEME_URL}/${path || TEMP_SCHEMA_PATH}`).then(response => {
        updateSchemaPath(path || TEMP_SCHEMA_PATH);
        updateSchema(response.data);
        updateSchemaFetchState('success');
        updateIddState(statusToKeep || 'schema_fetched');
      }).catch(error => {
        console.error(error);
        updateErrors(processAPIError(error, true, true) as TError[]);
        updateSchemaFetchState('failed');
      });
    });
  };

  const fetchQuestionnaires = () => {
    let query: Record<string, string | number> = {};

    if (customerData) {
      query.customer_id = customerData.id;
    }

    const queryString = makeRequestQuery(query);
    updateSchemaFetchState('loading');

    preflightAPICall(() => {
      axios.get(`${API_IDD_QUESTIONNAIRE_URL}${queryString}`).then(response => {
        if (response.data?.results.length) {
          const idd = response.data.results[0];
          updateFilledIdd(idd);
          if (handleIDDUpdate) {
            handleIDDUpdate(idd);
          }
          updateIddState('exist');
          updateSchemaPath(idd.schema_path);
          fetchIddSchemaByPath(idd.schema_path, 'exist');
        } else {
          updateSchemaFetchState(null);
          updateIddState('not_exist');
        }

      }).catch((error) => {
        console.error(error);
        updateErrors(processAPIError(error, true, true) as TError[]);
        updateSchemaFetchState('failed');
      });
    });
  };

  const isValid = (form: Record<string, any>, schemaProps: JSONSchemaProp) => {
    updateErrors([]);
    let errorsUI: TError[] = [];

    const requiredFields = schemaProps.required || [];
    const props = schemaProps?.properties;

    if (form['policy_numbers']?.length) {
      requiredFields.push('policy_numbers');
    }

    if (form['comment']) {
      requiredFields.push('comment');
    }

    if (form['others_comment']) {
      requiredFields.push('others_comment');
    }

    Object.keys(form).forEach(key => {
      if (COMMENT_FIELDS.includes(key) && form[key].includes('other')) {
        requiredFields.push(`${key}_comment`);
      }
    });

    if (!form.refusal && !schemaProps.if?.properties?.refusal.const && schemaProps.then?.required) {
      requiredFields.push(...schemaProps.then?.required);
    }

    requiredFields.forEach((item) => {
      const rules = setRules({
        props,
        item,
      });

      const errors = validate({
        field: item,
        fieldName: props && props[item] ? translateTitleInScheme(props[item]) : undefined,
        value: form[item],
        rules
      });

      if (errors?.length) {
        errorsUI = [
          ...errorsUI,
          ...errors
        ];
      }

    });

    if (!customerData) {
      errorsUI = [
        ...errorsUI,
        {
          field: 'customer',
          code: '',
          message: translate({ key: 'idd.error.no_customer' })
        }
      ];
    }

    if (errorsUI?.length) {
      // if (updateOutsideErrors) {
      //   updateOutsideErrors(errorsUI);
      // } else {
      updateErrors(errorsUI);
      // }

      updateSaveIDDState('failed');
      return false;
    }

    return true;
  };

  const saveIDD = (form: Record<string, any>) => {
    const props = JSON.parse(JSON.stringify(schema?.properties?.answers));

    if (props && props.properties && schema?.properties) {
      props.properties.comment = schema.properties.comment;
      props.properties.policy_numbers = schema.properties.policy_numbers;
    }

    if (!isValid({ ...form.answers, comment: form.comment, policy_numbers: form.policyNumbers }, props || {})) {
      return;
    }

    if (isGDPRTouched) {
      updateErrors([{
        field: 'customer',
        code: '',
        message: translate({ key: 'error.gdpr.unconfirmed' })
      }]);

      return;
    }

    updateSaveIDDState('loading');

    const data: Record<string, any> = {
      answers: processAnswers(form, schema as JSONSchemaProp),
      schema_path: schemaPath || TEMP_SCHEMA_PATH,
      policy_numbers: preparePolicyNumbers(form.policyNumbers),
      comment: form.comment,
    };

    if (customerData) {
      data.customer_data = customerData;
    }

    if (quoteId) {
      data.quote_id = quoteId;
    }

    preflightAPICall(() => {
      const request = filledIdd?.id && !isAddingNewIdd ? axios.patch(`${API_IDD_QUESTIONNAIRE_URL}/${filledIdd?.id}`, data) : axios.post(API_IDD_QUESTIONNAIRE_URL, data);

      request.then(response => {
        updateSaveIDDState('success');
        updateIddState('exist');
        updateFilledIdd(response.data);

        if (handleIDDUpdate) {
          handleIDDUpdate(response.data);
        }

        updateIsAddingNewIdd(false);
        updateIsFormTouched(false);
        updateIsEditingIdd(false);

        if (handleIddSave) {
          handleIddSave(response.data as TIDD);
        }

        if (filledIdd?.id) {
          const iddAddWrapper = document.querySelector('#js-idd-add-wrapper') as HTMLElement;

          if (iddAddWrapper) {
            iddAddWrapper.scrollTo({ top: 0, behavior: 'smooth' });
          }
        }
      }).catch(error => {
        console.error(error);
        updateErrors(processAPIQuoteError(error, true) as TError[]);
        updateSaveIDDState('failed');
      });
    });
  };

  const updateStatus = (status: number, preventStateUpdate?: boolean) => {
    preflightAPICall(() => {

      axios.patch(`${API_IDD_QUESTIONNAIRE_URL}/${filledIdd?.id}`, {
        status
      }).then(response => {
        updateSaveIDDState('success');

        if (!preventStateUpdate) {
          updateIddState('exist');
        }

        updateFilledIdd(response.data);

        if (handleIDDUpdate) {
          handleIDDUpdate(response.data);
        }
      }).catch(error => {
        console.error(error);
        updateErrors(processAPIError(error, true, true) as TError[]);
        updateSaveIDDState('failed');
      });
    });
  };

  const cancelIDD = () => {
    updateIsAddingNewIdd(false);
    updateIsEditingIdd(false);
    updateIddState(filledIdd ? 'exist' : 'not_exist');

    if (errors?.length) {
      updateErrors([]);
    }

    if (handleIddCancel) {
      handleIddCancel();
    }
  };

  const editIDD = () => {
    updateIsEditingIdd(true);
  };

  const downloadIdd = () => getFile({
    url: `${API_IDD_QUESTIONNAIRE_URL}/${filledIdd?.id || ''}/answers/download`,
    fileName: `${filledIdd?.customer_data.name || ''}-${filledIdd?.number || ''}.pdf`,
    onError: (error: AxiosError) => updateErrors(processAPIError(error, true, true) as TError[]),
    customConfig: {
      headers: {
        'Accept': 'application/octet-stream'
      }
    }
  });

  const sendEmail = () => {
    updateSaveIDDState('loading');
    getEmailTemplate(`${API_IDD_QUESTIONNAIRE_URL}/${filledIdd?.id || ''}`, (response) => {
      updateSaveIDDState('success');
      showDialog(setDialogProps({
        isEditEnabled: user?.props.idd_email_edit_content_preview || false,
        customerId: filledIdd?.customer_data.id as string,
        emailTemplate: response.data.template.content_html,
        typeId: TEMP_IDD_DOCUMENT_TYPE_ID,
        sendEmailUrl: `${API_IDD_QUESTIONNAIRE_URL}/${filledIdd?.id || ''}/send`,
        onSuccess: () => {
          updateSaveIDDState('success');

          const data = {
            ...filledIdd as TIDD,
            status: 3
          };

          updateFilledIdd(data);

          if (handleIDDUpdate) {
            handleIDDUpdate(data);
          }
        },
        onDecline: () => updateSaveIDDState('failed'),
        docs: response.data.template.documents || []
      }));
    }, (error: AxiosError) => {
      updateErrors(processAPIError(error, true, true) as TError[]);
      updateSaveIDDState('failed');
    });
  };

  const resetComponent = () => {
    updateFilledIdd(null);

    if (handleIDDUpdate) {
      handleIDDUpdate(undefined);
    }

    updateIsAddingNewIdd(false);
    updateIsEditingIdd(false);
  };

  const handleRemoveDoc = (id: string) => {
    const touchedList: TDoc[] = documentsList || [];
    const docIndex = touchedList.findIndex(i => i.id === id);
    if (docIndex !== -1) {
      touchedList?.splice(docIndex, 1);
      updateList(cloneObject(touchedList));
    }
  };

  useEffect(() => {
    if (errors?.length) {
      scrollToFirstError();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(errors)]);

  useEffect(() => {
    resetComponent();
    switch (initialIDDState) {
      case 'idd_requested':
        fetchLatestIDDSchema();
        break;
      case 'to_check_if_exist':
        fetchQuestionnaires();
        break;
      case 'exist':
        if (idd) {
          updateFilledIdd(idd);

          if (handleIDDUpdate) {
            handleIDDUpdate(idd);
          }

          updateSchemaPath(idd.schema_path);
          fetchIddSchemaByPath(idd.schema_path);
        }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerData?.id, customerData?.revision_id, idd?.id]);

  const statusRelatedControls = {
    1: [{
      icon: 'mail',
      title: 'idd.controls.send_for_approval',
      action: sendEmail
    }],
  };

  const shouldRenderControlsTop = (((iddState === 'schema_fetched' && schemaFetchState === 'success') || (iddState === 'exist' && showOnly)) && (filledIdd && !isFormTouched && !isAddingNewIdd)) || ((iddState === 'exist' && !showOnly) && filledIdd);

  if (schemaFetchState === 'loading') {
    return <Loader />;
  }

  if (schemaFetchState === 'failed') {
    return (
      <>
        {renderErrors(errors)}
      </>
    );
  }

  return (
    <IDDWrapper>
      <IDDTitle>
        {translate({ key: 'common.idd_title' })}
      </IDDTitle>
      {filledIdd && updateStatus ? (
        <IDDTable
          dataStatus="success"
          data={[{ number: filledIdd.number, status: filledIdd.status, updated_at: filledIdd?.updated_at || filledIdd.created_at }] as never[]}
          noResultsText={translate({ key: 'common.no_results' })}
          showHeadline={true}
          columns={[
            {
              title: translate({ key: 'idd.number' }),
              onRender: (data) => renderIDDNumber(data, isStatusListOpened, updateIsStatusListOpened),
              name: 'number',
              styles: {
                flex: 2,
              }
            },
            {
              name: 'status',
              title: translate({ key: 'common.status' }),
              onRender: (data) => <IDDStatus data={data} updateStatus={updateStatus} isStatusListOpened={isStatusListOpened} updateIsStatusListOpened={updateIsStatusListOpened} />,
              styles: {
                flex: 1
              }
            },
            {
              name: 'updated_at',
              title: translate({ key: 'common.updated_at' }),
              onRender: renderIDDUpdatedDate,
              styles: {
                flex: 1,
              }
            },
          ]}
        />
      ) : null}
      {shouldRenderControlsTop ? (
        <ControlsTop
          filledIdd={filledIdd}
          downloadIdd={downloadIdd}
          statusRelatedControls={statusRelatedControls}
          documentsList={documentsList}
          handleRemoveDoc={handleRemoveDoc}
          highlighted={highlighted}
          fetchCurrentIddSchema={fetchCurrentIddSchema}
          fetchLatestIDDSchema={fetchLatestIDDSchema}
          updateHighlighted={updateHighlighted}
          readOnly={readOnly}
          shouldRenderExistedIDD={iddState === 'exist' && !showOnly}
          editIDD={editIDD}
        />
      ) : null}
      {saveIDDState === 'loading' ? (
        <LoadingOverlay />
      ) : null}
      {iddState === 'not_exist' ? (
        <IDDButton
          highlighted={highlighted}
          preset="secondary"
          onClick={() => fetchLatestIDDSchema()}
          onAnimationEnd={() => updateHighlighted && updateHighlighted(false)}
          disabled={readOnly}
        >
          {translate({ key: 'buttons.fill_idd_form' })}
        </IDDButton>
      ) : null}
      {(iddState === 'schema_fetched' && schemaFetchState === 'success') ? (
        <IDDForm
          schemaProps={schema?.properties?.answers || {}}
          saveIDD={saveIDD}
          cancelIDD={cancelIDD}
          filledIdd={filledIdd}
          schemaPath={schemaPath}
          hidePolicyNumber={hidePolicyNumber}
          schemaErrors={errors}
          updateSchemaErrors={updateErrors}
          isAddingNewIdd={isAddingNewIdd}
          isFormTouched={isFormTouched}
          updateIsFormTouched={updateIsFormTouched}
          isEditingIdd={isEditingIdd}
          editIDD={editIDD}
          isIDDFormDisabled={!!filledIdd?.id && !isAddingNewIdd && !isEditingIdd}
        />
      ) : null}
      {(iddState === 'exist' && showOnly) ? (
        <IDDForm
          schemaProps={schema?.properties?.answers || {}}
          saveIDD={saveIDD}
          cancelIDD={cancelIDD}
          filledIdd={filledIdd}
          schemaPath={schemaPath}
          hidePolicyNumber={hidePolicyNumber}
          schemaErrors={errors}
          updateSchemaErrors={updateErrors}
          isAddingNewIdd={isAddingNewIdd}
          isFormTouched={isFormTouched}
          updateIsFormTouched={updateIsFormTouched}
          isEditingIdd={isEditingIdd}
          editIDD={editIDD}
          isIDDFormDisabled={!!filledIdd?.id && !isAddingNewIdd && !isEditingIdd}
        />
      ) : null}
      {errors.length ? (
        <>
          {renderErrors(errors)}
        </>
      ) : null}
    </IDDWrapper>
  );

};

const setLatestAPKDate = (date: string) => translate({ key: 'idd.latest_apk_date', replace: [new Date(date).toLocaleDateString(DEFAULT_LOCALE)] });

const IDDForm = ({
  schemaProps,
  saveIDD,
  cancelIDD,
  filledIdd,
  schemaPath,
  hidePolicyNumber,
  schemaErrors,
  updateSchemaErrors,
  isAddingNewIdd,
  isFormTouched,
  updateIsFormTouched,
  isEditingIdd,
  editIDD,
  isIDDFormDisabled
} : {
  schemaProps: JSONSchemaProp,
  saveIDD: (data: Record<string, any>) => void,
  cancelIDD: () => void,
  filledIdd: TIDD | null,
  schemaPath: string,
  hidePolicyNumber?: boolean,
  schemaErrors: TError[],
  updateSchemaErrors: (errors: TError[]) => void,
  isAddingNewIdd: boolean,
  isFormTouched: boolean,
  updateIsFormTouched: (isFormTouched: boolean) => void,
  isEditingIdd: boolean,
  editIDD: () => void,
  isIDDFormDisabled?: boolean,
}) => {

  const [form, updateForm] = useState<Record<string, any>>({});

  useEffect(() => {
    if (isAddingNewIdd) {
      updateForm({});
      return;
    }

    if (filledIdd?.schema_path === schemaPath) {
      updateForm(cloneObject({
        answers: filledIdd.answers,
        policyNumbers: !hidePolicyNumber && filledIdd.policy_numbers ? filledIdd.policy_numbers : [],
        comment: filledIdd.comment || '',
      }));
      updateIsFormTouched(false);
    }
    // eslint-disable-next-line
  }, [JSON.stringify(filledIdd), isAddingNewIdd]);

  const formChange = (name: string, value: Record<string, any> | string | string[]) => {

    updateForm({
      ...form,
      [name]: value,
    });

    updateIsFormTouched(true);

    if (schemaErrors?.length) {
      updateSchemaErrors([]);
    }

  };

  return (
    <>
      <IDDFormWrapper isDisabled={isIDDFormDisabled}>
        {!hidePolicyNumber ? (
          <PolicyNumbers
            policyNumbers={form.policyNumbers}
            formChange={formChange}
          />
        ) : null}
        {renderIDDAdditionalProperties({
          form: form.answers ? cloneObject(form.answers) : {},
          formChange: (data) => formChange('answers', data),
          schemaProps: schemaProps?.properties || {},
          fullForm: form as Record<string, any>,
          customAdditionalProperties: schemaProps?.properties,
          customRequiredFields: (!form.refusal && !schemaProps.if?.properties?.refusal.const) ? schemaProps.then?.required : schemaProps.required,
        })}
        <IddInput
          textarea={true}
          label={translate({ key: 'idd.comment' })}
          name="comment"
          value={form.comment || ''}
          handleChange={(name, value) => formChange(name as string, value as string)}
        />
      </IDDFormWrapper>
      <Button preset="secondary" size="l" onClick={cancelIDD} >{translate({ key: 'buttons.cancel_idd_form' })}</Button>
      {(filledIdd?.id && !isAddingNewIdd && !isEditingIdd) ? (
        <Button size="l" onClick={() => editIDD()} style={{ marginLeft: '16px' }}>{translate({ key: 'buttons.edit_idd_form' })}</Button>
      ) : null}
      {isFormTouched ? (
        <Button size="l" onClick={() => saveIDD(form)} style={{ marginLeft: '16px' }}>{translate({ key: 'buttons.save_idd_form' })}</Button>
      ) : null}
    </>
  );
};

const PolicyNumbers = ({
  policyNumbers,
  formChange
} : {
  policyNumbers: string[],
  formChange: (name: string, value: string[]) => void,
}) => {

  const [policyNumber, updatePolicyNumber] = useState('');

  const handleArrayItemChange = (value: any, index: number) => {
    const touchedForm = [...policyNumbers];
    touchedForm[index] = value;

    formChange('policyNumbers', touchedForm);
  };

  const handleRemoveArrayItem = (index: number) => {
    const touchedForm = [...policyNumbers];
    touchedForm.splice(index, 1);

    formChange('policyNumbers', touchedForm);
  };

  const handleAddArrayItem = () => {
    const touchedForm = policyNumbers ? [...policyNumbers] : [];
    touchedForm.push(policyNumber);
    updatePolicyNumber('');
    formChange('policyNumbers', touchedForm);
  };

  return (
    <IDDNumbersWrapper>
      <ParticipantsBlockHead>
        <AddPolicyNumberWrapper>
          <AddPolicyNumberInput
            value={policyNumber}
            handleChange={(key, value) => updatePolicyNumber(value as string)}
            placeholder={translate({ key: 'idd.add_policy_number_placeholder' })}
          />
        </AddPolicyNumberWrapper>
        <ButtonTransparent
          preset="transparent"
          onClick={handleAddArrayItem}
          disabled={policyNumber.length === 0}
        >
          <>
            {translate({ key: 'idd.add_policy_number' })}
            <IconPlus icon="plus" />
          </>
        </ButtonTransparent>
      </ParticipantsBlockHead>
      <IDDNumbersWrapperBody>
        {policyNumbers?.map((item, index) => (
          <IDDNumbersItemWrapper key={`policy-number-${index}`}>
            <Input
              label={translate({ key: 'idd.policy_number' })}
              value={item}
              handleChange={(name, value) => handleArrayItemChange(value, index)}
            />
            <DefaultIcon icon="trash" onClick={() => handleRemoveArrayItem(index)} />
          </IDDNumbersItemWrapper>
        ))}
      </IDDNumbersWrapperBody>
    </IDDNumbersWrapper>
  );
};

const ControlsTop = ({
  filledIdd,
  downloadIdd,
  statusRelatedControls,
  documentsList,
  handleRemoveDoc,
  highlighted,
  fetchCurrentIddSchema,
  fetchLatestIDDSchema,
  updateHighlighted,
  readOnly,
  shouldRenderExistedIDD,
  editIDD,
} : {
  filledIdd: TIDD,
  downloadIdd: () => void,
  statusRelatedControls: TStatusRelatedControls,
  documentsList: TDoc[],
  handleRemoveDoc: (id: string) => void,
  highlighted?: boolean,
  fetchCurrentIddSchema: () => void,
  fetchLatestIDDSchema: (isAddNewIdd?: boolean) => void,
  updateHighlighted?: (highlighted: boolean) => void,
  readOnly?: boolean,
  shouldRenderExistedIDD: boolean,
  editIDD: () => void,
}) => (
  <ControlsRowWrapper>
    <ControlsRow>
      {shouldRenderExistedIDD ? (
        <ExistedIDD>
          <DefaultIcon icon="nav_quote" />
          {filledIdd?.updated_at ? setLatestAPKDate(filledIdd.updated_at) : filledIdd?.created_at ? setLatestAPKDate(filledIdd.created_at) : null}
          <IDDButton
            size="s"
            highlighted={highlighted}
            preset="secondary"
            onClick={() => {
              editIDD();
              fetchCurrentIddSchema();
            }}
            onAnimationEnd={() => updateHighlighted && updateHighlighted(false)}
            disabled={readOnly}
          >
            {translate({ key: 'buttons.update_idd_form' })}
          </IDDButton>
          <IDDButton
            size="s"
            highlighted={highlighted}
            onClick={() => fetchLatestIDDSchema(true)}
            onAnimationEnd={() => updateHighlighted && updateHighlighted(false)}
            disabled={readOnly}
          >
            {translate({ key: 'buttons.fill_idd_form' })}
          </IDDButton>
        </ExistedIDD>
      ) : null}
      {statusRelatedControls[filledIdd.status]?.map((statusRelatedControlsItem) => (
        <StatusControls key={statusRelatedControlsItem.title} statusRelatedControl={statusRelatedControlsItem} />
      ))}
      {filledIdd.status === 3 ? statusRelatedControls[1]?.map((statusRelatedControlsItem) => (
        <StatusControls key={statusRelatedControlsItem.title} statusRelatedControl={statusRelatedControlsItem} />
      )) : null}
      <IDDControlsButton size="s" onClick={downloadIdd}>
        <ButtonInner>
          {translate({ key: 'idd.controls.download' })}
          <IDDControlsIcon icon="download" />
        </ButtonInner>
      </IDDControlsButton>
    </ControlsRow>
    {documentsList.length ? (
      <DocumentsList
        refId={filledIdd.id}
        dataStatus="success"
        data={documentsList as never[]}
        noResultsText={''}
        handleRemoveDoc={handleRemoveDoc}
      />
    ) : null}
  </ControlsRowWrapper>
);

export const StatusToggler = ({ status, updateStatus }: { status: number, updateStatus: (status: number) => void }) => (
  <Tooltip
    message={translate({ key: `idd.status.${status}` })}
    align="top"
    fixed
  >
    <SelectStatus
      key="source-select"
      name="source"
      value={MANUAL_STATUSES.includes(status) ? status : translate({ key: `idd.status.${status}` })}
      placeholder={translate({ key: 'idd.status.unknown' })}
      options={prepareStatusOptions()}
      handleChange={(name, value) => {
        updateStatus(value as number);
      }}
    />
  </Tooltip>
);

const prepareStatusOptions = () => MANUAL_STATUSES.map((status) => ({
  key: status,
  value: translate({ key: `idd.status.${status}` })
}));

const StatusControls = ({ statusRelatedControl }: { statusRelatedControl: TStatusRelatedControl }) => (
  <IDDControlsButton size="s" onClick={statusRelatedControl?.action}>
    <ButtonInner>
      {translate({ key: statusRelatedControl?.title })}
      <IDDControlsIcon icon={statusRelatedControl?.icon} />
    </ButtonInner>
  </IDDControlsButton>
);

export const setCustomerData = (userData: TCustomer) => ({
  id: userData.id as string,
  revision_id: userData.revision_id as string,
  name: userData.props.company_name ? `${userData.props.company_name}` : `${userData.props.first_name} ${userData.props.last_name}`,
  pesel: userData.props.pesel,
  idcode: userData.props.idCode,
  email: getCustomerContact(userData.contacts, 'email'),
  phone: getCustomerContact(userData.contacts, 'phone'),
});

const getCustomerContact = (contacts: TCustomerContact[] = [], type: 'phone' | 'email') => {
  let contact = '';
  if (contacts?.length) {
    return contacts.find(contact => contact.type === type)?.value || '';
  }

  return contact;
};

const preparePolicyNumbers = (policyNumbers?: string[]) => {
  policyNumbers = policyNumbers?.filter(policyNumber => typeof policyNumber === 'string' && policyNumber.trim() !== '');

  if (policyNumbers?.length) {
    return policyNumbers;
  } else {
    return [];
  }
};

const processAnswers = (form: Record<string, any>, schema: JSONSchemaProp) => {
  if (!form.answers) {
    return undefined;
  }

  const props: JSONSchema = schema.properties?.answers?.properties || {};

  let processedAnswers: Record<string, any> = {};

  Object.keys(form.answers).forEach(item => {
    if (checkShowIf(props[item], form)) {
      processedAnswers[item] = form.answers[item];
    }
  });

  if (Object.keys(processedAnswers).length === 0) {
    return undefined;
  } else {
    return processedAnswers;
  }

};

const IDDStatus = ({
  data,
  updateStatus,
  isStatusListOpened,
  updateIsStatusListOpened
}: {
  data: TIDDTable,
  updateStatus: (status: number, preventStateUpdate?: boolean) => void,
  isStatusListOpened: boolean,
  updateIsStatusListOpened: (isStatusListOpened: boolean) => void,
}) => {
  const statusListRef = useRef<HTMLUListElement>(null);
  const isOutside = useOutsideClick(statusListRef);

  useEffect(() => {
    if (isOutside) {
      updateIsStatusListOpened(false);
    }
  }, [isOutside]);

  return (
    <StatusCol isRelative>
      <DefaultIcon icon={STATUS_ICONS[data.status]} />
      <StatusTitle>
        {translate({ key: `idd.status.${data.status}` })}
      </StatusTitle>
      {isStatusListOpened && (
        <StatusList ref={statusListRef}>
          {MANUAL_STATUSES.map(status => (
            <StatusListItem key={status} onClick={(event) => {
              event.stopPropagation();
              updateIsStatusListOpened(false);
              updateStatus(status);
            }}>
              <StatusCol>
                <DefaultIcon icon={STATUS_ICONS[status]} />
                <StatusTitle>
                  {translate({ key: `idd.status.${status}` })}
                </StatusTitle>
              </StatusCol>
            </StatusListItem>
          ))}
        </StatusList>
      )}
    </StatusCol>
  );
};

const renderIDDUpdatedDate = (data: TIDDTable) => (
  <ColData>
    {data.updated_at ? new Date(data.updated_at).toLocaleString(DEFAULT_LOCALE) : ''}
  </ColData>
);

const renderIDDNumber = (data: TIDDTable, isStatusListOpened: boolean, updateIsStatusListOpened: (isStatusListOpened: boolean) => void) => (
  <IDDNumberColData>
    <IDDSubTitle>
      {data.number}
    </IDDSubTitle>
    <EditStatusIcon icon="edit_filled" onClick={() => updateIsStatusListOpened(!isStatusListOpened)} />
  </IDDNumberColData>
);

export default IDD;
