import styled from 'styled-components';
import { SvgIcon, FormGroup, DatePicker, Option } from '@insly/qmt-reactjs-ui-lib';
import { SMALL_SCREEN_WIDTH } from 'styles/common';
import { SectionHeadline } from 'utils/JSONSchemaFormBuilder/styles';

const contentHorizontalPadding = '7.224vw'; //~104px at 1440px
const contentHorizontalPaddingWide = '2vw'; //~32px at 1440

export const FormGroupContainer = styled(FormGroup)<{isCepik?: boolean}>`
  border: ${({ isCepik }) => isCepik && 'none'};
  &:hover {
    border: ${({ isCepik }) => isCepik && 'none'};
  }
`;

export const DatePickerComponent = styled(DatePicker)<{isCepik?: boolean}>`
  label {
    border: ${({ isCepik }) => isCepik && 'none'};
  }
`;

export const ContentBody = styled.div<{borderTop?: boolean}>`
  ${({ borderTop, theme }) => borderTop ? `
    border-top: 1px solid ${theme.colors.strokeA};
  ` : null}
`;

export const ContentBodyRow = styled.div<{gridTemplate?: string, noGrid?: boolean, justifyItems?: string, noMargin?: boolean}>`
  display: ${({ noGrid }) => noGrid ? 'block' : 'grid'};
  grid-gap: 16px;
  grid-template-columns: ${({ gridTemplate }) => gridTemplate ? gridTemplate : '1fr'};
  margin-bottom: ${({ noMargin }) => noMargin ? 0 : '40px'};
  justify-items: ${({ justifyItems }) => justifyItems ? justifyItems : null};
    
  &.form-section--others {
    margin-bottom: 0;
  }

  &.form-section--agreementforemail {
    ${SectionHeadline} {
        ${({ theme }) => theme.text.normal};
        font-weight: 500;
    }
  }

  @media (max-width: ${SMALL_SCREEN_WIDTH}) {
    grid-gap: 8px;
    margin-bottom: ${({ noMargin }) => noMargin ? 0 : '20px'};
  }
`;

export const ContentBodyRowWithTopPadding = styled(ContentBodyRow)<{ paddingTop: string }>`
  padding-top: ${({ paddingTop }) => paddingTop};
`;

export const ProductOption = styled(Option)`
  height: 60px;
  width: 126px;
  gap: 4px;
  
  .qmt-ui-checkbox {
    position: static;
    order: -1;
  }
`;

export const ContentInner = styled.div<{ wide?: boolean }>`
  padding-left: ${({ wide }) => wide ? contentHorizontalPaddingWide : contentHorizontalPadding};
  padding-right: ${({ wide }) => wide ? contentHorizontalPaddingWide : contentHorizontalPadding};
  transition: padding 0.3s ease;
  &.page-quote {
    display: flex;
    flex-direction: column;
    min-height: 100%;
  }
`;

export const ContentInnerComponent = styled(ContentInner)`
  @media (max-width: ${SMALL_SCREEN_WIDTH}) {
    padding-left: 20px;
    padding-right: 20px;
  }
`;

export const ContentSubheadline = styled.h3`
  ${({ theme }) => theme.text.headerH3};
  color: #282828;
  margin-bottom: 40px;
  @media (max-width: ${SMALL_SCREEN_WIDTH}) {
    font-size: 95%;
    margin-bottom: 20px;
  }
`;

//Common
export const IconPlus = styled(SvgIcon)`
  width: 24px;
  height: 24px;
  margin-left: 8px;
  stroke: ${({ theme }) => theme.colors.black};
`;
